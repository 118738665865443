import { ApolloProvider } from '@apollo/client';
import { PubNubProvider } from 'pubnub-react';
import { BrowserRouter } from 'react-router-dom';

import { createPubNubClient } from './common/PubNub/helpers';
import { UserProvider } from './common/authentication/UserContext';
import { PermissionsProvider } from './common/context/PermissionContext/PermissionContext';
import Router from './common/router/components/Router';
import { UtmProvider } from './common/utm/UtmContext';
import { apolloClient } from './doctor/graphql/client';

function App() {
  return (
    <PubNubProvider client={createPubNubClient()}>
      <ApolloProvider client={apolloClient}>
        <UtmProvider>
          <UserProvider>
            <PermissionsProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </PermissionsProvider>
          </UserProvider>
        </UtmProvider>
      </ApolloProvider>
    </PubNubProvider>
  );
}

export default App;
