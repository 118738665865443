import { useMutation } from '@apollo/client';
import { ArrowsExpandIcon, PaperClipIcon } from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import { usePubNub } from 'pubnub-react';
import { FormEvent, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { ConsultationConversationProps } from './ConsultationConversation.typs';
import {
  createDoctorFilePayload,
  createDoctorMessagePayload,
  createPubNubUuid,
} from '../../../../common/PubNub/helpers';
import * as api from '../../../../common/api/api';
import { UserContext } from '../../../../common/authentication/UserContext';
import { SCREEN_2XL, SCREEN_MD } from '../../../../common/constants/mediaQueries';
import { EMPTY_CONTENT, FILE_TYPE } from '../../../../common/constants/message';
import { deletePrescription, storePrescriptions } from '../../../../common/consultation/api';
import { MessageFileInput, MessageTextInput } from '../../../../common/consultation/types';
import ListConversation from '../../../../common/conversation/ListConversation';
import { stripEmptyPTags } from '../../../../common/conversation/helpers';
import { Button } from '../../../../common/form/components';
import QuillEditor from '../../../../common/form/components/QuillEditor';
import { SuccessNotification } from '../../../../common/notification/components';
import { CannedResponse, DoctorCannedResponse } from '../../../../common/template/types';
import { enableSkincarePlanButton } from '../../../../common/utils/feature-flags';
import { classNames } from '../../../../common/utils/style';
import { DELETE_DRAFT_MESSAGE, UPSERT_DRAFT_MESSAGE } from '../../../graphql/models/DraftMessage';
import { CREATE_CONSULTATION_NOTE, DELETE_CONSULTATION_NOTE } from '../../../graphql/mutations/consultation_notes';
import {
  ConsultationAttachment,
  ConsultationNote,
  DraftMessage,
  FileMessage,
  Message as MessageType,
  Message,
} from '../../../graphql/types';
import TemplateSearch from '../../../template/components/TemplateSearch';
import { FileList } from '../FileList';
import OpenUntilTag from '../OpenUntilTag';
import RookooToggle from '../RookooToggle';
import SkincareToggleButton from '../Skincare/ToggleButton';

export const ConsultationConversation = memo((props: ConsultationConversationProps) => {
  const {
    attachments,
    channel,
    consultationId,
    createCallback,
    hasCreator,
    messages,
    notes,
    openUntil,
    readPermission,
    status,
    user,
    draftMessage,
    rookoo,
  } = props;
  const { message: pubNubMessage } = useContext(UserContext);
  const pubNubClient = usePubNub();
  const { t } = useTranslation();
  const [communicationSent, setCommunicationSent] = useState<boolean>(false);
  const [createConsultationNote] = useMutation(CREATE_CONSULTATION_NOTE);
  const [deleteConsultationNote] = useMutation(DELETE_CONSULTATION_NOTE);
  const [upsertDraftMessage] = useMutation(UPSERT_DRAFT_MESSAGE);
  const [deleteDraftMessage] = useMutation(DELETE_DRAFT_MESSAGE);
  const isMdScreen = useMediaQuery(SCREEN_MD);
  const is2XlScreen = useMediaQuery(SCREEN_2XL);
  const [expandedView, setExpandedView] = useState<boolean>(false);
  const [stateMessages, setStateMessages] = useState<Message[]>(messages);
  const [stateConsultationNotes, setStateConsultationNotes] = useState<ConsultationNote[]>(notes);
  const [statePrescriptions, setStatePrescriptions] = useState<File[]>([]);
  const [contentSubmitting, setContentSubmitting] = useState<boolean>(false);
  const [content, setContent] = useState<string>(draftMessage?.content ?? '');
  const [noteStorageEnabled, setNoteStorageEnabled] = useState<boolean>(false);
  const [stateDraftMessage, setStateDraftMessage] = useState<DraftMessage | null>(draftMessage ?? null);

  useEffect(() => {
    setStateMessages(messages);
    setStateConsultationNotes(notes);
  }, [messages, notes]);

  useEffect(() => {
    setContent(draftMessage?.content ?? '');
    setStateDraftMessage(draftMessage ?? null);
    setNoteStorageEnabled(false);
  }, [consultationId]);

  // TODO: can be removed when we shut down PubNub
  useEffect(() => {
    const subscribedChannels = pubNubClient.getSubscribedChannels();

    if (!subscribedChannels.includes(channel)) {
      pubNubClient.subscribe({ channels: [channel] });
    }
  }, [pubNubClient]);

  // TODO: can be removed when we shut down PubNub
  useEffect(() => {
    const currentUser = createPubNubUuid(user);

    if (pubNubMessage && pubNubMessage.channel === channel && pubNubMessage.publisher !== currentUser) {
      const pubNubMessageId = pubNubMessage.message.custom.message.id.toString() ?? '0';
      setStateMessages((prevMessages) => {
        const messageExists = prevMessages.some((message) => message.id === pubNubMessageId);
        if (!messageExists) {
          return [...prevMessages, pubNubMessage.message.custom.message];
        }

        return prevMessages;
      });
    }
  }, [pubNubMessage]);

  // TODO: can be removed when we shut down PubNub
  const publishPubNubMessage = async (storedMessage: Message, attachment?: ConsultationAttachment) => {
    let payload;

    if (storedMessage.body_type === 'file') {
      payload = createDoctorFilePayload(channel, storedMessage, attachment as ConsultationAttachment);
    } else {
      payload = createDoctorMessagePayload(channel, storedMessage);
    }

    await pubNubClient.publish(payload);
  };

  // TODO: move to graphql
  const createMessageHandler = async (
    messageInput: MessageTextInput | MessageFileInput,
    attachment?: ConsultationAttachment
  ): Promise<void> => {
    const { data: createMessageResponse } = await api.storeMessage(user, consultationId, messageInput);
    const storedMessage = createMessageResponse.data.message;

    setStateMessages([...stateMessages, createMessageResponse.data.message]);
    await publishPubNubMessage(storedMessage, attachment);
  };

  // TODO: move to graphql
  const deleteMessageHandler = async (id: string): Promise<void> => {
    const { data: deleteMessageResponse } = await api.deleteMessage(user, consultationId, id);

    setStateMessages((stateMessages) =>
      stateMessages.map((stateMessage) =>
        stateMessage.id === id
          ? { ...stateMessage, deleted_at: deleteMessageResponse.data.message.deleted_at }
          : stateMessage
      )
    );
  };

  const upsertDraftMessageHandler = async (content: string): Promise<void> => {
    const response = await upsertDraftMessage({
      variables: {
        id: stateDraftMessage?.id,
        userId: user.id.toString(),
        consultationId,
        content: stripEmptyPTags(content),
      },
    });

    if (response.data.upsertDraftMessage) {
      setStateDraftMessage(response.data.upsertDraftMessage);
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  const deleteDraftMessageHandler = async (): Promise<void> => {
    const response = await deleteDraftMessage({
      variables: {
        id: stateDraftMessage?.id,
      },
    });

    if (response.data.deleteDraftMessage) {
      setStateDraftMessage(null);
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  // TODO: move to graphql
  const createPrescriptionsHandler = async (): Promise<void> => {
    if (!statePrescriptions.length) {
      return;
    }

    const storedPrescriptions = await storePrescriptions(user, consultationId, statePrescriptions);

    storedPrescriptions.map(async (prescription) => {
      await createMessageHandler({
        label: prescription.name,
        url: prescription.file,
        type: 'file',
      });
    });
  };

  // TODO: move to graphql
  const deletePrescriptionHandler = async (id: string, fileMessageBody: FileMessage): Promise<void> => {
    if (attachments.length) {
      const attachmentToDelete = attachments.find(
        (attachment: ConsultationAttachment) =>
          attachment.file === fileMessageBody.url && attachment.name === fileMessageBody.label
      );
      if (attachmentToDelete) {
        await deletePrescription(user, consultationId, attachmentToDelete.id);
      }
    }
    await deleteMessageHandler(id);
  };

  const deleteStatePrescriptionsHandler = (indexToRemove: number) => {
    setStatePrescriptions(statePrescriptions.filter((_, index) => index !== indexToRemove));
  };

  const createConsultationNoteHandler = async (): Promise<void> => {
    const response = await createConsultationNote({
      variables: {
        userId: user.id.toString(),
        consultationId,
        content: stripEmptyPTags(content),
      },
    });

    if (response.data.createConsultationNote) {
      setStateConsultationNotes([...stateConsultationNotes, response.data.createConsultationNote]);
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  const deleteConsultationNoteHandler = async (id: string): Promise<void> => {
    const response = await deleteConsultationNote({
      variables: {
        id,
      },
    });

    if (response.data.deleteConsultationNote) {
      setStateConsultationNotes((stateConsultationNotes) =>
        stateConsultationNotes.map((stateConsultationNote) =>
          stateConsultationNote.id === id
            ? { ...stateConsultationNote, deleted_at: response.data.deleteConsultationNote.deleted_at }
            : stateConsultationNote
        )
      );
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  const deleteItemCallbackHandler = async (item: MessageType | ConsultationNote): Promise<void> => {
    if ('body_type' in item) {
      switch (item.body_type) {
        case FILE_TYPE:
          await deletePrescriptionHandler(item.id, item.body as FileMessage);
          break;
        default:
          await deleteMessageHandler(item.id);
          break;
      }
    } else {
      await deleteConsultationNoteHandler(item.id);
    }

    createCallback();
  };

  const selectTemplateCallbackHandler = (value: CannedResponse | DoctorCannedResponse) => {
    setContent(`${content} ${value.template}`);
    mixpanel.track(`doctor:templates:selected_${value.title.toLowerCase().replaceAll(' ', '_')}_${value.id}`);
  };

  const selectPrescriptionsHandler = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = 'application/pdf';
    input.style.display = 'none';
    document.body.appendChild(input);

    input.onchange = (event: Event) => {
      setStatePrescriptions(Array.from((event.target as HTMLInputElement).files as FileList));
      document.body.removeChild(input);
    };
    input.click();
  };

  const calculateHeight = (): string => {
    if (is2XlScreen && hasCreator) {
      return '247px';
    } else if (isMdScreen && hasCreator) {
      return '264px';
    } else {
      return '183px';
    }
  };

  const onSubmitHandler = async (event: FormEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    const hasContent = content !== '' && content !== EMPTY_CONTENT;

    if (!contentSubmitting && (hasContent || !!statePrescriptions.length)) {
      try {
        setContentSubmitting(true);
        setCommunicationSent(false);

        if (noteStorageEnabled && hasContent) {
          await createConsultationNoteHandler();
        } else if (!noteStorageEnabled && hasContent) {
          await createMessageHandler({
            content: stripEmptyPTags(content),
            type: 'text',
          });
        }
        setContent('');
        setStateDraftMessage(null);

        await createPrescriptionsHandler();
        setStatePrescriptions([]);

        createCallback();
        setContentSubmitting(false);
        setCommunicationSent(true);
      } catch (errors) {
        // TODO: handle errors
        console.error(errors);
        setContentSubmitting(false);
      }
    }
  };

  const onBlurHandler = async (event: FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (readPermission || noteStorageEnabled || stateDraftMessage?.content === content) {
      return;
    }

    if (content.length && content !== EMPTY_CONTENT) {
      await upsertDraftMessageHandler(content);
    } else if (stateDraftMessage) {
      await deleteDraftMessageHandler();
    }
  };

  const toggleExpandedView = (force?: boolean) => {
    setExpandedView(force || !expandedView);
  };

  return (
    <div className="flex flex-col w-full" style={isMdScreen ? { height: `calc(100vh - ${calculateHeight()})` } : {}}>
      <SuccessNotification
        title={t(
          `doctor:consultation:communication_status:${noteStorageEnabled ? 'consultation_note' : 'message'}:title`
        )}
        message={t(
          `doctor:consultation:communication_status:${noteStorageEnabled ? 'consultation_note' : 'message'}:desc`
        )}
        isOpen={communicationSent}
      />

      <div className="px-4 py-6 sm:px-6 flex-grow overflow-y-auto">
        <ListConversation
          deleteCallbackHandler={deleteItemCallbackHandler}
          messages={stateMessages}
          readonly={status === 'closed' || status === 'draft'}
          notes={stateConsultationNotes}
        />
      </div>

      <div className="h-80 flex-shrink-0">
        {expandedView && (
          <div
            onClick={() => {
              toggleExpandedView(false);
            }}
            className="bg-black opacity-50 fixed z-30 top-0 right-0 bottom-0 left-0"
          />
        )}
        <div
          className={classNames(
            'flex flex-col gap-2 p-6',
            expandedView ? 'p-8 bg-white fixed z-50 rounded-lg quill-full' : 'h-full'
          )}
          style={
            expandedView
              ? {
                  height: '80vh',
                  top: '50%',
                  right: '10%',
                  left: '10%',
                  transform: 'translateY(-50%)',
                }
              : {}
          }
        >
          {openUntil && (status === 'reminded' || status === 'closed') && (
            <div className="self-start">
              <OpenUntilTag openUntil={openUntil} />
            </div>
          )}

          <TemplateSearch
            selectCallbackHandler={selectTemplateCallbackHandler}
            popoverPlacement={expandedView ? 'bottom' : 'top'}
            consultationNoteEnabled={noteStorageEnabled}
          />

          <form className="flex flex-col flex-grow gap-4 relative" onSubmit={onSubmitHandler} onBlur={onBlurHandler}>
            <button
              className="absolute z-30 top-3 right-2"
              onClick={(event) => {
                event.preventDefault();
                toggleExpandedView();
              }}
            >
              <ArrowsExpandIcon className="flex-shrink-0 h-5 w-5 text-gray-400 hover:text-gray-600" />
            </button>

            <QuillEditor
              content={content}
              consultationNoteEnabled={noteStorageEnabled}
              setContent={setContent}
              setConsultationNoteEnabled={setNoteStorageEnabled}
            />

            <div className="flex items-center">
              <FileList prescriptions={statePrescriptions} onRemovePrescription={deleteStatePrescriptionsHandler} />
              <div className="ml-auto flex gap-2">
                <RookooToggle changeCallback={createCallback} consultationId={consultationId} rookoo={rookoo} />
                <button
                  disabled={readPermission}
                  onClick={selectPrescriptionsHandler}
                  type="button"
                  className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                >
                  <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" />
                </button>
                {enableSkincarePlanButton(user) && <SkincareToggleButton disabled={readPermission} />}
                <Button
                  label={t('common:send')}
                  type="submit"
                  loading={contentSubmitting}
                  disabled={contentSubmitting || readPermission}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
