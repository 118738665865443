import { ChangeEvent, useState } from 'react';

import { SkincarePlans } from './SkincarePlanPrompt.types';

const SKINCARE_PLANS: SkincarePlans[] = [
  'inflammatoire-acne',
  'anti-aging',
  'acne-general',
  'acne-comedonica',
  'acne-isotretinoine',
  'rosacea',
  'post-acne',
  'grove-porien',
  'hyperpigmentatie',
  'eczeem',
  'seborrhoisch-eczeem',
];

export function SkincarePlanPrompt() {
  const [value, setValue] = useState<string>(SKINCARE_PLANS[0]);

  return (
    <div
      id="skincare-prompt"
      className="hidden w-80 absolute z-50 top-2/4 left-2/4 p-3 bg-gray-100 border border-gray-300 rounded-md"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <ul className="flex flex-wrap">
        {SKINCARE_PLANS.map((skincarePlan: SkincarePlans, index: number) => (
          <li className="mr-2 mb-2" key={`${index}-${skincarePlan}`}>
            <span
              onClick={() => setValue(skincarePlan)}
              className="p-1 cursor-pointer text-sm rounded-sm bg-gray-200 text-teal-700"
            >
              {skincarePlan}
            </span>
          </li>
        ))}
      </ul>

      <div className="flex mt-1">
        <input
          id="skincare-prompt__input"
          type="text"
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
          }}
          placeholder="Skincare Plan"
          className="appearance-none flex-grow sm:text-sm focus:outline-none focus:ring-0 focus:border-gray-300 border-gray-300 placeholder-gray-400 rounded-md"
        />
        <button
          onClick={() => setValue(SKINCARE_PLANS[0])}
          id="skincare-prompt__btn"
          type="button"
          className="px-4 py-2 ml-2 text-white bg-black hover:bg-gray-700 border-transparent border rounded-md shadow-sm text-sm font-medium"
        >
          Ok
        </button>
      </div>
    </div>
  );
}
