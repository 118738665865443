export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeTz: { input: any; output: any };
};

export enum AppPermissionName {
  ReadConsultation = 'read_consultation',
  ReadOthersConsultations = 'read_others_consultations',
  TransferConsultation = 'transfer_consultation',
  WriteConsultation = 'write_consultation',
  WriteOthersConsultations = 'write_others_consultations',
}

export type Consultable = CosmeticRequest | Problem;

export type Consultation = {
  __typename?: 'Consultation';
  attachments?: Maybe<ConsultationAttachment[]>;
  channel: Scalars['String']['output'];
  client: Scalars['String']['output'];
  closed_at?: Maybe<Scalars['DateTimeTz']['output']>;
  consultable: Consultable;
  consultable_id: Scalars['ID']['output'];
  consultable_type: Scalars['String']['output'];
  consultation_notes?: Maybe<ConsultationNote[]>;
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  deadline_at?: Maybe<Scalars['DateTimeTz']['output']>;
  diagnosis?: Maybe<Icd10Code>;
  doctor: User;
  doctor_id: Scalars['ID']['output'];
  draftMessage?: Maybe<DraftMessage>;
  feedback?: Maybe<Feedback>;
  follow_up_at?: Maybe<Scalars['DateTimeTz']['output']>;
  has_feedback: Scalars['Int']['output'];
  has_new_activity: Scalars['Boolean']['output'];
  icd10_code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  in_progress_at?: Maybe<Scalars['DateTimeTz']['output']>;
  is_paid: Scalars['Boolean']['output'];
  latest_consultation?: Maybe<Scalars['String']['output']>;
  latest_developments?: Maybe<Scalars['String']['output']>;
  medication?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Message[]>;
  next_consultation?: Maybe<Consultation>;
  next_consultation_id?: Maybe<Scalars['ID']['output']>;
  open_until?: Maybe<Scalars['DateTimeTz']['output']>;
  patient: User;
  patient_id: Scalars['ID']['output'];
  payments?: Maybe<Payment[]>;
  pivot?: Maybe<UserConsultationPivot>;
  previous_consultation?: Maybe<Consultation>;
  previous_consultation_id?: Maybe<Scalars['ID']['output']>;
  price: Scalars['String']['output'];
  pricing_tier: PricingTier;
  pricing_tier_id: Scalars['ID']['output'];
  reminded_at?: Maybe<Scalars['DateTimeTz']['output']>;
  response_time: Scalars['Int']['output'];
  response_time_label: Scalars['String']['output'];
  response_time_left: Scalars['Int']['output'];
  rookoo?: Maybe<Rookoo>;
  show_problem_pictures: Scalars['Boolean']['output'];
  skin_concern?: Maybe<Scalars['String']['output']>;
  skincare_proposal: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
  user_has_write_access: Scalars['Boolean']['output'];
  user_settings: User[];
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  weeks_until_follow_up?: Maybe<Scalars['Int']['output']>;
};

export type ConsultationAttachment = {
  __typename?: 'ConsultationAttachment';
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  file: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type ConsultationNote = {
  __typename?: 'ConsultationNote';
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  deleted_at?: Maybe<Scalars['DateTimeTz']['output']>;
  id: Scalars['ID']['output'];
  sender: User;
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type CosmeticRequest = {
  __typename?: 'CosmeticRequest';
  active_picture: Scalars['String']['output'];
  consultations?: Maybe<Consultation[]>;
  created_at: Scalars['DateTimeTz']['output'];
  expectations: Scalars['String']['output'];
  expected_treatments: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  previous_experiences: Scalars['String']['output'];
  previous_treatments: Scalars['String']['output'];
  resting_picture: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  push_gateway: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type DoctorCannedResponse = {
  __typename?: 'DoctorCannedResponse';
  created_at: Scalars['DateTimeTz']['output'];
  doctor: User;
  doctor_id: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  sanitized_template: Scalars['String']['output'];
  template: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type DoctorSettings = {
  __typename?: 'DoctorSettings';
  about_en: Scalars['String']['output'];
  about_fr: Scalars['String']['output'];
  about_nl: Scalars['String']['output'];
  accepting_new_patients: Scalars['Boolean']['output'];
  allowed_postal_codes?: Maybe<Scalars['String']['output'][]>;
  average_response_time: Scalars['Int']['output'];
  consultation_transfer: Scalars['Boolean']['output'];
  countries?: Maybe<Scalars['String']['output'][]>;
  created_at: Scalars['DateTimeTz']['output'];
  education: Scalars['String']['output'];
  has_reached_weekly_volume_limit: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  is_away: Scalars['Boolean']['output'];
  license_number: Scalars['String']['output'];
  mediris_integration: Scalars['Boolean']['output'];
  phone_number: Scalars['String']['output'];
  practice: Scalars['String']['output'];
  practice_city_en: Scalars['String']['output'];
  practice_city_fr: Scalars['String']['output'];
  practice_city_nl: Scalars['String']['output'];
  response_time: Scalars['String']['output'];
  start_professional_career: Scalars['DateTimeTz']['output'];
  supported_languages: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
  verified_at: Scalars['DateTimeTz']['output'];
  weekly_volume_limit: Scalars['Int']['output'];
  years_experience: Scalars['Int']['output'];
};

export type DraftConsultation = {
  __typename?: 'DraftConsultation';
  created_at: Scalars['DateTimeTz']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reminded_at: Scalars['DateTimeTz']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user_id: Scalars['ID']['output'];
};

export type DraftMessage = {
  __typename?: 'DraftMessage';
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  nps: Scalars['Int']['output'];
  remarks: Scalars['String']['output'];
  satisfaction: Scalars['Int']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type FileMessage = {
  __typename?: 'FileMessage';
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  message?: Maybe<Message[]>;
  updated_at: Scalars['DateTimeTz']['output'];
  url: Scalars['String']['output'];
};

export type GetDoctorConsultations = {
  inboxPreset: InboxPreset;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort_by?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
};

export type GetDoctorPatients = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  search_by: Scalars['String']['input'];
};

export type Icd10Code = {
  __typename?: 'ICD10Code';
  code: Scalars['String']['output'];
  consultations?: Maybe<Consultation[]>;
  created_at: Scalars['DateTimeTz']['output'];
  description: Scalars['String']['output'];
  exclusion: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inclusion: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type ImageMessage = {
  __typename?: 'ImageMessage';
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Message[]>;
  updated_at: Scalars['DateTimeTz']['output'];
  url: Scalars['String']['output'];
};

export enum InboxPreset {
  All = 'all',
  InProgress = 'inProgress',
  New = 'new',
}

export type Invitation = {
  __typename?: 'Invitation';
  created_at: Scalars['DateTimeTz']['output'];
  doctor: User;
  doctor_id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last_name: Scalars['String']['output'];
  reminded_at: Scalars['DateTimeTz']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type Message = {
  __typename?: 'Message';
  body: MessageBody;
  body_id: Scalars['ID']['output'];
  body_type: Scalars['String']['output'];
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  deleted_at?: Maybe<Scalars['DateTimeTz']['output']>;
  id: Scalars['ID']['output'];
  read_receipts?: Maybe<MessageReadReceipt[]>;
  sender: User;
  sender_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type MessageBody = FileMessage | ImageMessage | TextMessage;

export type MessageReadReceipt = {
  __typename?: 'MessageReadReceipt';
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  message: Message;
  message_id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  assignConsultation?: Maybe<Consultation>;
  createConsultationNote: ConsultationNote;
  createSkincarePlan?: Maybe<Consultation>;
  deleteConsultationNote?: Maybe<ConsultationNote>;
  deleteDraftMessage?: Maybe<DraftMessage>;
  toggleRookooFeature?: Maybe<Consultation>;
  transferConsultation?: Maybe<Consultation>;
  updateDoctorConsultationIsFlagged?: Maybe<Consultation>;
  upsertDraftMessage: DraftMessage;
};

export type MutationAssignConsultationArgs = {
  consultation_id: Scalars['ID']['input'];
  user_id: Scalars['ID']['input'];
};

export type MutationCreateConsultationNoteArgs = {
  consultation_id: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  user_id: Scalars['ID']['input'];
};

export type MutationCreateSkincarePlanArgs = {
  consultation_id: Scalars['ID']['input'];
  skincarePlan: SkincarePlan;
};

export type MutationDeleteConsultationNoteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteDraftMessageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationToggleRookooFeatureArgs = {
  consultation_id: Scalars['ID']['input'];
};

export type MutationTransferConsultationArgs = {
  consultation_id: Scalars['ID']['input'];
  doctor_id: Scalars['ID']['input'];
};

export type MutationUpdateDoctorConsultationIsFlaggedArgs = {
  consultation_id: Scalars['ID']['input'];
  isFlagged: Scalars['Boolean']['input'];
};

export type MutationUpsertDraftMessageArgs = {
  consultation_id: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  user_id: Scalars['ID']['input'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM',
}

export type PatientSettings = {
  __typename?: 'PatientSettings';
  allergies?: Maybe<Scalars['String']['output']>;
  birth_control: Scalars['Boolean']['output'];
  country: Scalars['String']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  date_of_birth?: Maybe<Scalars['DateTimeTz']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identification_number?: Maybe<Scalars['String']['output']>;
  is_pregnant: Scalars['Boolean']['output'];
  medication?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTimeTz']['output'];
  user: User;
  user_id: Scalars['ID']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['String']['output'];
  checkout: Scalars['String']['output'];
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  description: Scalars['String']['output'];
  discount_code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: Scalars['String']['output'];
  mollie_id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type Permission = {
  __typename?: 'Permission';
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  guard_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: AppPermissionName;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
};

export type PricingTier = {
  __typename?: 'PricingTier';
  active: Scalars['Boolean']['output'];
  consultations?: Maybe<Consultation[]>;
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  perks?: Maybe<PricingTierPerk[]>;
  price: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type PricingTierPerk = {
  __typename?: 'PricingTierPerk';
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  perk: Scalars['String']['output'];
  pricing_tier: PricingTier;
  pricing_tier_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  value: Scalars['String']['output'];
};

export type Problem = {
  __typename?: 'Problem';
  consultations?: Maybe<Consultation[]>;
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  detail_picture: Scalars['String']['output'];
  discolouration?: Maybe<Scalars['String']['output']>;
  doctor_treatments?: Maybe<Scalars['String']['output']>;
  first_occurred: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  other_sensational_symptoms?: Maybe<Scalars['String']['output']>;
  other_visual_symptoms?: Maybe<Scalars['String']['output']>;
  overview_picture: Scalars['String']['output'];
  patient_treatments?: Maybe<Scalars['String']['output']>;
  pictures?: Maybe<ProblemPicture[]>;
  remarks?: Maybe<Scalars['String']['output']>;
  sensational_symptoms?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
  user: User;
  user_id: Scalars['ID']['output'];
  visual_symptoms?: Maybe<Scalars['String']['output']>;
};

export type ProblemPicture = {
  __typename?: 'ProblemPicture';
  consultation: Consultation;
  consultation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  picture: Scalars['String']['output'];
  problem: Problem;
  problem_id: Scalars['ID']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export type Query = {
  __typename?: 'Query';
  activeDoctors?: Maybe<User[]>;
  doctorConsultation?: Maybe<Consultation>;
  getDoctorConsultations: Consultation[];
  getDoctorPatients: User[];
  me?: Maybe<User>;
};

export type QueryDoctorConsultationArgs = {
  consultation_id: Scalars['ID']['input'];
};

export type QueryGetDoctorConsultationsArgs = {
  input: GetDoctorConsultations;
};

export type QueryGetDoctorPatientsArgs = {
  input: GetDoctorPatients;
};

export type Role = {
  __typename?: 'Role';
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  guard_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Permission[]>;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
};

export type Rookoo = {
  __typename?: 'Rookoo';
  consultation: User;
  consultation_id: Scalars['ID']['output'];
  contact_id: Scalars['ID']['output'];
  conversation_id: Scalars['ID']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
};

export enum SkincarePlan {
  AcneComedonica = 'acneComedonica',
  AcneGeneral = 'acneGeneral',
  AcneIsotretinoine = 'acneIsotretinoine',
  AntiAging = 'antiAging',
  Custom = 'custom',
  Eczeem = 'eczeem',
  GrovePorien = 'grovePorien',
  Hyperpigmentatie = 'hyperpigmentatie',
  InflammatoireAcne = 'inflammatoireAcne',
  PostAcne = 'postAcne',
  Rosacea = 'rosacea',
  SeborrhoischEczeem = 'seborrhoischEczeem',
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC',
}

export type TextMessage = {
  __typename?: 'TextMessage';
  content: Scalars['String']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Message[]>;
  updated_at: Scalars['DateTimeTz']['output'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT',
}

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  avatar: Scalars['String']['output'];
  channel: Scalars['String']['output'];
  consultation_count: Scalars['Int']['output'];
  consultation_notes?: Maybe<ConsultationNote[]>;
  consultation_settings: Consultation[];
  cosmetic_requets?: Maybe<CosmeticRequest[]>;
  created_at: Scalars['DateTimeTz']['output'];
  created_on: Scalars['String']['output'];
  created_users?: Maybe<User[]>;
  creator?: Maybe<User>;
  creator_id: Scalars['ID']['output'];
  device_tokens?: Maybe<DeviceToken[]>;
  doctor_canned_responses?: Maybe<DoctorCannedResponse[]>;
  doctor_consultations: Consultation[];
  doctor_invitations?: Maybe<Invitation[]>;
  doctor_settings?: Maybe<DoctorSettings>;
  draft_consultation?: Maybe<DraftConsultation>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified_at: Scalars['DateTimeTz']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_admin: Scalars['Boolean']['output'];
  is_doctor: Scalars['Boolean']['output'];
  is_medical_care: Scalars['Boolean']['output'];
  is_verified_doctor: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  marketing_opt_in: Scalars['Boolean']['output'];
  messages?: Maybe<Message[]>;
  password: Scalars['String']['output'];
  patient_consultations?: Maybe<Consultation[]>;
  patient_settings: PatientSettings;
  permissions?: Maybe<Permission[]>;
  pivot?: Maybe<UserConsultationPivot>;
  problems?: Maybe<Problem[]>;
  remember_token: Scalars['String']['output'];
  roles?: Maybe<Role[]>;
  source: Scalars['String']['output'];
  updated_at: Scalars['DateTimeTz']['output'];
  utm_campaign: Scalars['String']['output'];
  utm_medium: Scalars['String']['output'];
  utm_source: Scalars['String']['output'];
  utm_term: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type UserConsultationPivot = {
  __typename?: 'UserConsultationPivot';
  assigned: Scalars['Boolean']['output'];
  assigned_at: Scalars['DateTimeTz']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  is_flagged: Scalars['Boolean']['output'];
  is_read: Scalars['Boolean']['output'];
  read_at?: Maybe<Scalars['DateTimeTz']['output']>;
  unread_at?: Maybe<Scalars['DateTimeTz']['output']>;
  updated_at: Scalars['DateTimeTz']['output'];
};
