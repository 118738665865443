export const TEXT_TYPE = 'App\\Models\\TextMessage';
export const IMAGE_TYPE = 'App\\Models\\ImageMessage';
export const FILE_TYPE = 'App\\Models\\FileMessage';
export const STATUS_READ = 'read';
export const STATUS_UNREAD = 'unread';

export const EMPTY_CONTENT = '<p><br></p>';

export const PUBNUB_TEXT_TYPE = 'text';
export const PUBNUB_IMAGE_TYPE = 'image';
export const PUBNUB_FILE_TYPE = 'file';
