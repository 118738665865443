import mixpanel from 'mixpanel-browser';
import type { MessageEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from './types';
import { createPubNubUuid, PUBNUB_ANONYMOUS_USER_ID, PUBNUB_CONSULTATION_CHANNEL_PREFIX } from '../PubNub/helpers';
import { PUBNUB_FILE_TYPE, PUBNUB_IMAGE_TYPE, PUBNUB_TEXT_TYPE } from '../constants/message';

interface IUserContext {
  user: User | null;
  message: MessageEvent | null;
  setAuthenticatedUser: (user: User) => void;
  unsetAuthenticatedUser: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export function UserProvider(props: Props) {
  const { children } = props;
  const [user, setUser] = useState<User | null>(determineInitialUser());
  const [message, setMessage] = useState<MessageEvent | null>(null);
  const { i18n } = useTranslation();
  const pubNubClient = usePubNub();

  useEffect(() => {
    if (user !== null && pubNubClient.getUUID() !== user.id.toString()) {
      const uuidUser = createPubNubUuid(user);
      pubNubClient.setUUID(uuidUser);
      pubNubClient.objects.setUUIDMetadata({
        uuid: uuidUser,
        data: {
          name: `${user.first_name} ${user.last_name}`,
          externalId: user.id.toString(),
          profileUrl: user.avatar,
          email: user.email,
        },
      });
      pubNubClient.addListener({
        message: handleInComingMessage,
      });
    } else {
      pubNubClient.setUUID(PUBNUB_ANONYMOUS_USER_ID);
      pubNubClient.objects.setUUIDMetadata({
        uuid: PUBNUB_ANONYMOUS_USER_ID,
        data: {
          name: PUBNUB_ANONYMOUS_USER_ID,
        },
      });
      pubNubClient.removeListener({
        message: handleInComingMessage,
      });
    }
  }, [user]);

  function handleInComingMessage(messageEvent: MessageEvent) {
    if (!messageEvent.channel.startsWith(PUBNUB_CONSULTATION_CHANNEL_PREFIX)) {
      return;
    }

    const type = messageEvent.message?.content?.type;

    if (type !== PUBNUB_TEXT_TYPE && type !== PUBNUB_IMAGE_TYPE && type !== PUBNUB_FILE_TYPE) {
      throw new Error(`Unhandled notification type: ${type}`);
    }

    setMessage(messageEvent);
  }

  function determineInitialUser() {
    const storedUserJson = localStorage.getItem('user');
    if (storedUserJson !== null) {
      const storedUser = JSON.parse(storedUserJson);
      identifyMixpanel(storedUser);
      identifyGoogleAnalytics(storedUser);
      return storedUser;
    }

    return null;
  }

  function setAuthenticatedUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('i18nextLng', user.language ?? 'nl');
    i18n.changeLanguage(user.language ?? 'nl');
    identifyMixpanel(user);
    identifyGoogleAnalytics(user);
    setUser(user);
  }

  function unsetAuthenticatedUser() {
    localStorage.removeItem('user');
    setUser(null);
  }

  function identifyMixpanel(user: User) {
    // It seems like register and people.set are separate things, so we'll perform both for now.
    mixpanel.identify(user.id.toString());
    mixpanel.register({
      Name: `${user.first_name} ${user.last_name}`,
      Email: `${user.email}`,
      Role: user.is_doctor ? 'doctor' : 'patient',
    });
    mixpanel.people.set({
      Name: `${user.first_name} ${user.last_name}`,
      Email: `${user.email}`,
      Role: user.is_doctor ? 'doctor' : 'patient',
    });

    // Send extra patient properties if available.
    if (user.patient_settings) {
      mixpanel.people.set({
        Country: user.patient_settings?.country ?? '',
        PostalCode: user.patient_settings?.postal_code ?? '',
        Gender: user.patient_settings?.gender ?? '',
      });
    }
  }

  function identifyGoogleAnalytics(user: User) {
    (window as any)?.dataLayer?.push({
      name: `${user.first_name} ${user.last_name}`,
      email: `${user.email}`,
      role: user.is_doctor ? 'doctor' : 'patient',
    });

    if (user.patient_settings) {
      (window as any)?.dataLayer?.push({
        country: user.patient_settings?.country ?? '',
        postal_code: user.patient_settings?.postal_code ?? '',
        gender: user.patient_settings?.gender ?? '',
        phone_number: user.patient_settings?.phone_number ?? '',
      });
    }
  }

  const value = {
    user,
    message,
    setAuthenticatedUser,
    unsetAuthenticatedUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
