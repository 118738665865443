import PubNub from 'pubnub';

import type { ImagePayload, MessagePayload } from './types';
import type {
  Consultation,
  Message,
  FileMessage,
  TextMessage,
  ConsultationAttachment,
} from '../../doctor/graphql/types';
import type { User } from '../authentication/types';
import { PUBNUB_FILE_TYPE, PUBNUB_IMAGE_TYPE, PUBNUB_TEXT_TYPE } from '../constants/message';

export const PUBNUB_ANONYMOUS_USER_ID = 'anonymous';
export const PUBNUB_DOCTOR_PREFIX = 'doctor';
export const PUBNUB_MEDICAL_CARE_PREFIX = 'medical_care';
export const PUBNUB_PATIENT_PREFIX = 'patient';
export const PUBNUB_API_USER = process.env.REACT_APP_PUBNUB_API_USER;
export const PUBNUB_CONSULTATION_CHANNEL_PREFIX = 'consultations';

/**
 * Create PubNub client
 */
export function createPubNubClient(): PubNub {
  return new PubNub({
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    uuid: PUBNUB_ANONYMOUS_USER_ID,
  });
}

/**
 * Create PubNub uuid
 *
 * @param user
 * @return string
 */
export function createPubNubUuid(user: User): string {
  let prefix = PUBNUB_PATIENT_PREFIX;
  if (user.is_doctor || user.is_medical_care) {
    prefix = PUBNUB_DOCTOR_PREFIX;
  } else if (user.is_medical_care) {
    prefix = PUBNUB_MEDICAL_CARE_PREFIX;
  }

  return `${prefix}.${user.id}`;
}

/**
 * Extend payload with consultation channel & patient meta
 *
 * @param payload
 * @param user
 * @param consultation
 */
function extendPayloadWithChannelAndMeta(
  payload: MessagePayload | ImagePayload,
  user: User,
  consultation: Consultation
) {
  return {
    channel: consultation.channel,
    message: {
      content: payload,
    },
    meta: {
      accessToken: user?.access_token,
      consultationId: consultation.id,
      receiverId: consultation.doctor.id,
    },
  };
}

/**
 * Create PubNub message payload
 *
 * @param user
 * @param consultation
 * @param message
 */
export function createPatientMessagePayload(user: User, consultation: Consultation, message: string) {
  return extendPayloadWithChannelAndMeta(
    {
      type: PUBNUB_TEXT_TYPE,
      message,
    },
    user,
    consultation
  );
}

/**
 * Create PubNub image payload
 *
 * @param user
 * @param consultation
 * @param url
 */
export function createPatientImagePayload(user: User, consultation: Consultation, url: string) {
  return extendPayloadWithChannelAndMeta(
    {
      type: PUBNUB_IMAGE_TYPE,
      url,
    },
    user,
    consultation
  );
}

/**
 * Create PubNub doctor message payload
 */
export function createDoctorMessagePayload(consultationChannel: string, storedMessage: Message) {
  return {
    channel: consultationChannel,
    message: {
      content: {
        type: PUBNUB_TEXT_TYPE,
        message: (storedMessage.body as TextMessage).content,
      },
      custom: {
        message: storedMessage,
      },
    },
  };
}

/**
 * Create PubNub file payload
 */
export function createDoctorFilePayload(
  consultationChannel: string,
  storedMessage: Message,
  storedFile: ConsultationAttachment
) {
  const { label, url } = storedMessage.body as FileMessage;

  return {
    channel: consultationChannel,
    message: {
      content: {
        type: PUBNUB_FILE_TYPE,
        label,
        url,
      },
      custom: {
        message: storedMessage,
        file: storedFile,
      },
    },
  };
}
