import { CosmeticRequestInformation } from './CosmeticRequestInformation';
import LightGalleryWrapper from '../../../common/LightgalleryWrapper';
import ProblemFollowUpInformation from '../../../common/problem/components/ProblemFollowUpInformation';
import ProblemInformation from '../../../common/problem/components/ProblemInformation';
import type { Consultation, Problem, CosmeticRequest } from '../../graphql/types';

interface Props {
  consultation: Consultation;
}

export function ConsultationDetailProblemView(props: Props) {
  const { consultation } = props;

  return (
    <div className="flex flex-1 flex-col border-b md:border-b-0">
      <LightGalleryWrapper consultation={consultation} />
      <div className="px-4 sm:px-6 pb-4 md:pb-6 bg-white">
        {consultation.consultable_type !== 'App\\Models\\CosmeticRequest' ? (
          <>
            {consultation.previous_consultation_id ? (
              <ProblemFollowUpInformation consultation={consultation} patient={consultation.patient} />
            ) : (
              <ProblemInformation
                consultation={consultation}
                problem={consultation.consultable as Problem}
                patient={consultation.patient}
              />
            )}
          </>
        ) : (
          <CosmeticRequestInformation
            cosmeticRequest={consultation.consultable as CosmeticRequest}
            patient={consultation.patient}
          />
        )}
      </div>
    </div>
  );
}
